import { useState } from "react";
import { ChatList } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/ChatList";
import { Header } from "@/react/components/SettingsApp/CommunityInbox/Sidebar/Header";
import {
  CHAT_SORT_VALUES,
  CHAT_TYPES,
} from "@/react/components/SettingsApp/CommunityInbox/Sidebar/constants";
import { AiInboxTitle } from "@circle-react/components/SettingsApp/CommunityInbox/Sidebar/AiInboxTitle";
import { useCommunityInboxStore } from "@circle-react/hooks/chatsV2/store";
import type {
  ChatSortType,
  ChatType,
} from "@circle-react/types/CommunityBot/ChatSort";

export const CommunityInboxLinks = () => {
  const { totalUnreadPausedAiChatsCount = 0 } = useCommunityInboxStore();
  const [activeSort, setActiveSort] = useState<ChatSortType>(
    CHAT_SORT_VALUES.LATEST,
  );

  const [activeChatType, setActiveChatType] = useState<ChatType>(
    totalUnreadPausedAiChatsCount > 0 ? CHAT_TYPES.AI_PAUSED : CHAT_TYPES.ALL,
  );
  const { adminChats: chats } = useCommunityInboxStore();

  const shouldShowHeader =
    activeChatType !== CHAT_TYPES.ALL || Boolean(chats.length);
  return (
    <div className="block h-full">
      <AiInboxTitle />
      {shouldShowHeader && (
        <Header
          activeSort={activeSort}
          setActiveSort={setActiveSort}
          activeChatType={activeChatType}
          setActiveChatType={setActiveChatType}
        />
      )}
      <ChatList activeSort={activeSort} activeChatType={activeChatType} />
    </div>
  );
};

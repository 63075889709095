import { useMemo } from "react";
import type { ComponentType } from "react";
import {
  useIsHeadlineAsCustomProfileFieldEnabled,
  useIsProfileFieldsEnabled,
} from "@circle-react-shared/ProfileFields/useIsProfileFieldsEnabled";
import { useProfileFieldsList } from "./ProfileFieldFilter";
import type { RenderFilter, RenderProfileFieldFilter } from "./types";

export const useProfileFieldFilters = ({
  platformFilters = [],
  component,
}: {
  platformFilters: RenderFilter[];
  /** Have a specific prop type */
  component: ComponentType<any>;
}) => {
  const isProfileFieldsEnabled = useIsProfileFieldsEnabled();
  const isHeadlineAsCustomProfileFieldEnabled =
    useIsHeadlineAsCustomProfileFieldEnabled();
  const shouldShowLegacyHeadlineFilter =
    isProfileFieldsEnabled && !isHeadlineAsCustomProfileFieldEnabled;
  const hasProfileFieldsFilters = Boolean(isProfileFieldsEnabled);

  const {
    getOptions: {
      data: customProfileFields,
      isLoading: isLoadingProfileFields,
    },
  } = useProfileFieldsList();

  const filterProfileFields = (filters: RenderFilter[]) => {
    if (!hasProfileFieldsFilters) {
      return filters;
    }

    return filters.filter(({ name }) => !["bio", "location"].includes(name));
  };

  const filterHeadlineFromDefaultFilters = (filters: RenderFilter[]) => {
    if (shouldShowLegacyHeadlineFilter) {
      return filters;
    }

    return filters.filter(({ name }) => name !== "headline");
  };

  const filteredDefaultFilters = filterHeadlineFromDefaultFilters(
    filterProfileFields(platformFilters),
  );
  const profileFieldRenderFilters: RenderProfileFieldFilter[] = useMemo(
    () =>
      customProfileFields?.map(customProfileField => ({
        ...customProfileField,
        component,
        name: "profile_field",
      })) ?? [],
    [customProfileFields, component],
  );

  return {
    filterProfileFields,
    filteredDefaultFilters,
    isLoadingProfileFields,
    customProfileFields: profileFieldRenderFilters,
    hasProfileFieldsFilters,
  };
};

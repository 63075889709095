import { isEmpty, isNil } from "lodash";
import { isSliderFilterParam } from "@circle-react-shared/Filter/FilterTypes/SliderFilter";
import {
  isStringComparatorParam,
  requireValueByFilterType,
} from "../Filter/FilterTypes/InputFilterComparator/stringComparators";
import type { BaseFilter } from "./types";

/**
 * Check if value is empty, so that it can be removed.
 */
export function getIsValueEmpty(filterParam: BaseFilter) {
  /* If there are more cases like this, consider moving the function to passed object itself instead of modifying here. */
  if (isSliderFilterParam(filterParam)) {
    const gteValue = filterParam.gte;
    const lteValue = filterParam.lte;
    return !!(isNil(gteValue) && isNil(lteValue));
  }
  if (
    isStringComparatorParam(filterParam) &&
    !requireValueByFilterType(filterParam.filter_type)
  ) {
    return false;
  }

  const selectedValue = filterParam?.value;

  if (isNil(selectedValue)) {
    return true;
  }

  if (typeof selectedValue === "number") {
    return false;
  }

  if (isEmpty(selectedValue)) {
    return true;
  }

  return undefined;
}

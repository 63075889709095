import { t } from "@/i18n-js/instance";
import { settingsNavPathV3 } from "@circle-react/helpers/urlHelpers/settingsNavPathV3";
import { NavGroup } from "../NavGroup";
import { SubNavLinkItem } from "./SubNavLinkItem";
import type { Link } from "./types";

export const BrandedAppsDashboardLinks = () => {
  const links: Link[] = [
    {
      label: t("settings_nav_v3.branded_apps_dashboard.customize"),
      path: settingsNavPathV3.plus.index,
      exact: true,
    },
    {
      label: t("settings_nav_v3.branded_apps_dashboard.billing"),
      path: settingsNavPathV3.plus.billing,
      exact: true,
    },
  ];

  return (
    <NavGroup groupLinks={links}>
      <NavGroup.Content
        title={t("settings_nav_v3.branded_apps_dashboard.title")}
      >
        {links.map(link => (
          <SubNavLinkItem
            isDisabled={link.isDisabled}
            key={link.path}
            label={link.label}
            path={link.path}
          />
        ))}
      </NavGroup.Content>
    </NavGroup>
  );
};
